import { state as init } from '../global_state';
import * as RequestsActions from './requests_actions';

const reducer = (state = init, action: any) => {
  switch (action.type) {
    case RequestsActions.GET_REQUESTS: {
      return { ...state, loading: true, error: '' };
    }

    case RequestsActions.REQUEST_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        ...payload,
      };
    }

    case RequestsActions.SET_LAST_FETCHED_REQUESTS:
      return {
        ...state,
        lastFetched: action.timestamp,
      };

    case RequestsActions.RESPOND_REQUEST: {
      return { ...state, loading: true, error: '' };
    }

    case RequestsActions.LOGOUT: {
      return {};
    }

    default:
      return state;
  }
};

export default reducer;
