import { RequestStatus, RequestType } from 'data/types/requests';

export const GET_REQUESTS = 'GET_REQUESTS';
export const SET_LAST_FETCHED_REQUESTS = 'SET_LAST_FETCHED_REQUESTS';
export const REQUEST_SUCCESS = 'REQUEST_SUCCESS';
export const RESPOND_REQUEST = 'RESPOND_REQUEST';
export const LOGOUT = 'LOGOUT';

export const actionGetRequests = (payload?: {
  page: number;
  statuses: RequestStatus[];
  per_page: number;
  request_type: RequestType[];
}) => ({
  type: GET_REQUESTS,
  payload,
});

export const actionRequestSuccess = (payload: any) => ({
  type: REQUEST_SUCCESS,
  payload,
});

export const actionSetLastFetchedRequests = (timestamp: number) => ({
  type: SET_LAST_FETCHED_REQUESTS,
  timestamp,
});

export const actionRespondToRequest = (payload: {
  data: {
    accepted: boolean;
    msg: string;
    request_id: number;
  };
  success: () => void;
}) => ({
  type: RESPOND_REQUEST,
  payload,
});

export const actionLogoutRequests = () => ({
  type: LOGOUT,
});
