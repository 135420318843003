import { FilterBtnsStyled } from 'silal_app_base_react/src/pages/orders_archive/components/archived_orders_components.styled';
import Button from 'silal_app_base_react/src/components/buttons';
import TransactionsRepository from 'data/repositories/transactions_repository';
import { toast } from 'react-toastify';
import { useState } from 'react';
import { PaymentTab } from '../payments_page';
import { MenuItem, Select } from '@mui/material';

type ActionBtnProps = {
  currentDatabase: string;
  ids: number[];
  refreshData: () => void;
};
export const ActionBtn = ({
  currentDatabase,
  ids,
  refreshData,
}: ActionBtnProps) => {
  const [dasFileDrivers, setDasFileDrivers] = useState(null);
  const [dasFileStores, setDasFileStores] = useState(null);

  const [paymentsFileDrivers, setPaymentsFileDrivers] = useState(null);
  const [paymentsFileStores, setPaymentsFileStores] = useState(null);

  const handleDASFileChangeDrivers = (e: any) => {
    setDasFileDrivers(e.target.files[0]);
  };
  const handleDASFileChangeStores = (e: any) => {
    setDasFileStores(e.target.files[0]);
  };

  const handlePaymentsFileChangeDrivers = (e: any) => {
    setPaymentsFileDrivers(e.target.files[0]);
  };
  const handlePaymentsFileChangeStores = (e: any) => {
    setPaymentsFileStores(e.target.files[0]);
  };
  return (
    <FilterBtnsStyled
      style={{
        gridTemplateColumns: 'repeat(4, 1fr)',
      }}
    >
      <DownloadExamples />
      <Button
        secondaryButton
        style={{
          fontSize: '14px',
          height: '40px',
        }}
        key="downloadCSV"
        text={`Selected ${
          currentDatabase === PaymentTab.DRIVERS ? 'Captains' : 'Stores'
        } (${ids.length})`}
        onClick={async () => {
          if (ids.length === 0) {
            toast.warn('Please select at least one item');
            return;
          }
          if (currentDatabase === PaymentTab.STORES) {
            const res =
              await TransactionsRepository.getStoresPaymentReportsCSV(ids);
            if (!res) return;
          } else if (currentDatabase === PaymentTab.DRIVERS) {
            const res =
              await TransactionsRepository.getDriversPaymentReportsCSV(ids);
            if (!res) return;
          }
        }}
      />
      <Button
        secondaryButton
        style={{
          fontSize: '14px',
          height: '40px',
        }}
        text="All Stores Report"
        onClick={async () => {
          await TransactionsRepository.getStoresPaymentReportsCSV([]);
        }}
      />
      <Button
        secondaryButton
        style={{
          fontSize: '14px',
          height: '40px',
        }}
        text="All Captains Report"
        onClick={async () => {
          await TransactionsRepository.getDriversPaymentReportsCSV([]);
        }}
      />
      {currentDatabase !== PaymentTab.STORES ? (
        <div
          className="filter"
          style={{
            marginTop: '20px',
          }}
        >
          <input
            type="file"
            name="file"
            accept=".csv"
            onChange={handleDASFileChangeDrivers}
            style={{
              marginBottom: '10px',
            }}
          />
          <Button
            text="Captains DAS +"
            style={{
              fontSize: '14px',
              height: '40px',
              cursor: dasFileDrivers ? 'pointer' : 'not-allowed',
            }}
            primaryButton={dasFileDrivers !== null}
            disabledButton={dasFileDrivers === null}
            onClick={async () => {
              if (!dasFileDrivers) {
                toast.warn('Please select a file');
                return;
              }
              const formData = new FormData();
              formData.append('csv_file', dasFileDrivers!);
              toast.warn('Please wait while we upload your file');
              const res =
                await TransactionsRepository.uploadDriverDasCSV(formData);
              if (!res) return;
              setDasFileDrivers(null);
              refreshData();
            }}
          />
        </div>
      ) : (
        <div
          className="filter"
          style={{
            marginTop: '20px',
          }}
        >
          <input
            type="file"
            name="file"
            accept=".csv"
            onChange={handleDASFileChangeStores}
            style={{
              marginBottom: '10px',
            }}
          />
          <Button
            text="Stores DAS +"
            style={{
              fontSize: '14px',
              height: '40px',
              cursor: dasFileStores ? 'pointer' : 'not-allowed',
            }}
            primaryButton={dasFileStores !== null}
            disabledButton={dasFileStores === null}
            onClick={async () => {
              if (!dasFileStores) {
                toast.warn('Please select a file');
                return;
              }
              const formData = new FormData();
              formData.append('csv_file', dasFileStores);
              const res =
                await TransactionsRepository.uploadStoreDasCSV(formData);
              if (!res) return;
              setDasFileStores(null);
              refreshData();
            }}
          />
        </div>
      )}

      {currentDatabase !== PaymentTab.STORES ? (
        <div
          className="filter"
          style={{
            marginTop: '20px',
          }}
        >
          <input
            type="file"
            name="file"
            accept=".csv"
            onChange={handlePaymentsFileChangeDrivers}
            style={{
              marginBottom: '10px',
            }}
          />
          <Button
            text="Captains Payments +"
            style={{
              fontSize: '14px',
              height: '40px',
              cursor: paymentsFileDrivers ? 'pointer' : 'not-allowed',
            }}
            primaryButton={paymentsFileDrivers !== null}
            disabledButton={paymentsFileDrivers === null}
            onClick={async () => {
              if (!paymentsFileDrivers) {
                toast.warn('Please select a file');
                return;
              }
              const formData = new FormData();
              formData.append('csv_file', paymentsFileDrivers!);
              const res =
                await TransactionsRepository.uploadDriverPaymentsCSV(formData);
              if (!res) return;
              setPaymentsFileDrivers(null);
              refreshData();
            }}
          />
        </div>
      ) : (
        <div
          className="filter"
          style={{
            marginTop: '20px',
          }}
        >
          <input
            type="file"
            name="file"
            accept=".csv"
            onChange={handlePaymentsFileChangeStores}
            style={{
              marginBottom: '10px',
            }}
          />
          <Button
            text="Stores Payments +"
            style={{
              fontSize: '14px',
              height: '40px',
              cursor: paymentsFileStores ? 'pointer' : 'not-allowed',
            }}
            primaryButton={paymentsFileStores !== null}
            disabledButton={paymentsFileStores === null}
            onClick={async () => {
              if (!paymentsFileStores) {
                toast.warn('Please select a file');
                return;
              }
              const formData = new FormData();
              formData.append('csv_file', paymentsFileStores);
              const res =
                await TransactionsRepository.uploadStorePaymentsCSV(formData);
              if (!res) return;
              setPaymentsFileStores(null);
              refreshData();
            }}
          />
        </div>
      )}
    </FilterBtnsStyled>
  );
};

export const DownloadExamples = () => {
  const [selectedExample, setSelectedExample] = useState('');

  const examples = [
    {
      label: 'Captains DAS CSV Example',
      headers: ['driver_id', 'das_percentage'],
      filename: 'drivers_das_example.csv',
    },
    {
      label: 'Stores DAS CSV Example',
      headers: ['store_id', 'das_percentage'],
      filename: 'stores_das_example.csv',
    },
    {
      label: 'Captains Payments CSV Example',
      headers: [
        'driver_id',
        'paid_amount',
        'transaction_id',
        'transaction_date',
      ],
      filename: 'drivers_payments_example.csv',
    },
    {
      label: 'Stores Payments CSV Example',
      headers: [
        'store_id',
        'paid_amount',
        'transaction_id',
        'transaction_date',
      ],
      filename: 'stores_payments_example.csv',
    },
  ];

  const downloadEmptyCsv = () => {
    const example = examples.find((item) => item.label === selectedExample);

    if (example) {
      const { headers, filename } = example;
      const emptyRow = headers.map(() => '').join(',');

      const csvContent = `data:text/csv;charset=utf-8,${headers.join(
        ',',
      )}\n${emptyRow}`;

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', filename);
      document.body.appendChild(link);

      link.click();

      // Remove the link from the document
      document.body.removeChild(link);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Select
        value={selectedExample}
        onChange={(e) => setSelectedExample(e.target.value)}
        displayEmpty
        style={{
          marginRight: '10px',
          height: '40px',
          fontSize: '14px',
          minWidth: '220px',
          maxWidth: '220px',
        }}
        color="success"
      >
        <MenuItem value="" disabled>
          Select an empty example
        </MenuItem>
        {examples.map((example, index) => (
          <MenuItem key={index} value={example.label}>
            {example.label}
          </MenuItem>
        ))}
      </Select>

      <Button
        secondaryButton
        style={{
          fontSize: '14px',
          height: '40px',
        }}
        // onClick={downloadEmptyCsv}
        onClick={() => downloadEmptyCsv()}
        disabled={!selectedExample}
      >
        Download
      </Button>
    </div>
  );
};
