import { all } from '@redux-saga/core/effects';
import { fork } from '@redux-saga/core/effects';
import * as Auth from './authentication/authentication_saga';
import * as Banners from './banners/banners_saga';
import * as Categories from './categories/categories_saga';
import * as Images from 'silal_app_base_react/src/store/images/image_library_saga';
import * as Locations from './locations/locations_saga';
import * as Orders from './orders/orders_saga';
import * as Requests from './requests/requests_saga';
import * as Shifts from './shifts/shifts_saga';
import * as Templates from './templates/templates_saga';
import * as CategoriesBase from 'silal_app_base_react/src/store/categories/categories_saga';

export default function* RootSaga() {
  const sagas = [
    // Authentications
    fork(Auth.sagaLogin),
    fork(Auth.sagaLoginMFAVerifyOTP),
    fork(Auth.sagaLogout),

    //Banners
    fork(Banners.sagaGetBanners),
    fork(Banners.sagaCreateBanner),
    fork(Banners.sagaDeleteBanner),
    fork(Banners.sagaUpdateBanner),
    fork(Banners.sagaPauseBanner),

    // Categories
    fork(Categories.sagaGetCategories),
    fork(CategoriesBase.sagaGetCategoriesMinimalList),
    fork(Categories.sagaGetSingleCategory),
    fork(Categories.sagaAddCategory),
    fork(Categories.sagaEditCategory),
    fork(Categories.sagaHideCategory),
    fork(Categories.sagaUnhideCategory),
    fork(Categories.sagaDeleteCategory),
    fork(Categories.sagaAddNewAttribute),
    fork(Categories.sagaEditAttribute),
    fork(Categories.sagaRemoveAttribute),

    // Images
    fork(Images.sagaGetSilalImages),

    // Locations
    fork(Locations.sagaGetCities),
    fork(Locations.sagaUpdateCity),
    fork(Locations.sagaGetTowns),
    fork(Locations.sagaUpdateTown),
    fork(Locations.sagaAddCity),
    fork(Locations.sagaAddTown),
    fork(Locations.sagaDeleteCity),
    fork(Locations.sagaDeleteTown),

    // Orders
    fork(Orders.sagaGetAllOrders),

    // Requests
    fork(Requests.sagaGetRequests),
    fork(Requests.sagaRespondToRequest),

    // Shifts
    fork(Shifts.sagaGetShiftsInCity),
    fork(Shifts.sagaCreateShift),
    fork(Shifts.sagaUpdateShift),
    fork(Shifts.sagaDeleteShift),
    fork(Shifts.sagaUnregisterCarrierFromShift),

    //Templates
    fork(Templates.sagaGetTemplates),
    fork(Templates.sagaCreateTemplate),
    fork(Templates.sagaDeleteTemplate),
  ];
  yield all([...sagas]);
}
