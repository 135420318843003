import { put, call, takeLatest } from 'redux-saga/effects';
import {
  GET_REQUESTS,
  RESPOND_REQUEST,
  actionGetRequests,
  actionRequestSuccess,
  actionSetLastFetchedRequests,
} from './requests_actions';
import RequestsRepository from 'data/repositories/requests_repository';
import { Request } from 'data/types/requests';

export function* sagaGetRequests() {
  function* getRequests(action: { type: string; payload: any }) {
    const payload: {
      requests: Request[];
    } = {
      requests: [],
    };
    const res: Request[] = yield call(RequestsRepository.getRequests, {
      page: action?.payload?.page,
      statuses: action?.payload?.statuses,
      per_page: action?.payload?.per_page,
      request_type: action?.payload?.request_type,
    });
    if (!res) return false;
    payload.requests = res;
    yield put(actionRequestSuccess(payload));
    yield put(actionSetLastFetchedRequests(new Date().getTime()));
  }
  yield takeLatest(GET_REQUESTS, getRequests);
}

export function* sagaRespondToRequest() {
  function* respondToRequest(action: {
    type: string;
    payload: {
      data: {
        request_id: number;
        accepted: boolean;
        msg: string;
      };
      success: () => void;
    };
  }) {
    const payload = action.payload.data;
    const res: boolean = yield call(
      RequestsRepository.respondToRequest,
      payload.request_id,
      payload.accepted,
      payload.msg,
    );
    if (!res) return false;
    action.payload.success();
    yield put(actionGetRequests());
  }
  yield takeLatest(RESPOND_REQUEST, respondToRequest);
}
